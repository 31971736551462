import {
  Box,
  Typography,
  Container,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  Grid,
  CardMedia,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ArrowBack from '@mui/icons-material/ArrowBack';
import { useRouter } from 'src/routes/hooks';
import { paths } from 'src/routes/paths';

const votingInfoData = [
  {
    title: 'Upoznajte Vatreni Glasanje',
    content:
      'Vatreni Glasanje omogućuje vam da glasate za svoje omiljene igrače u Hrvatskoj nogometnoj reprezentaciji. Ovdje možete izraziti svoje mišljenje i vidjeti koji igrači su najpopularniji među navijačima za svaku poziciju na terenu.',
  },
  {
    title: 'Pravila glasanja',
    content:
      'Za svaku poziciju potrebno je odabrati igrača te prijaviti idealnu formaciju. Jednom kada glasate, ne možete promijeniti svoj izbor. Svaka prijava idealne formacije ima svoju digitalnu vremensku oznaku.',
  },
  {
    title: 'Značaj vašeg glasa',
    content:
      'Vaš glas pomaže u kreiranju najpopularnije postave Vatrenih. Kroz glasanje, zajednica navijača zajedno odlučuje koji su igrači zaslužili posebno priznanje i tako dobivamo Najboljih 11 svih vremena!',
  },
  {
    title: 'Nagrade',
    content: (
      <div>
        Prvih 11 korisnika koji su točno odabrali Najboljih 11 za koje je
        glasala većina, osvajaju personaliziran dres hrvatske nogometne
        reprezentacije iz nove kolekcije i kapetansku traku!
        <br />
        <br />
        Dobitnici se biraju 15.7. nakon završetka natječaja kada se objavljuje
        idealna momčad za koje je glasala većina. Dobitnici su oni koji su točno
        odabrali i najranije prijavili svoju formaciju naspram ukupnih glasova
        većine.
      </div>
    ),
  },
];

const VotingRewardInfo = () => {
  const history = useRouter();
  const handleGoBack = () => {
    history.back();
  };
  return (
    <Container maxWidth='md'>
      <Box
        display='flex'
        alignItems='center'
        justifyContent='space-between'
        mt={2}
        mb={1}
      >
        <Button variant='contained' color='error' onClick={handleGoBack}>
          <ArrowBack />
        </Button>
        <Button
          variant='contained'
          color='secondary'
          onClick={() =>
            history.push(`${paths.dashboard.contestPolicy}/voting`)
          }
        >
          Pravila
        </Button>
      </Box>
      <Box
        display='flex'
        alignItems='center'
        justifyContent='center'
        mt={2}
        mb={2}
        sx={{ marginBottom: 4 }}
      >
        <Typography variant='h4'>Pravila i upute natječaja "Najboljih 11"</Typography>
      </Box>
      <Grid container spacing={2} justifyContent='center'>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <CardMedia
            component='img'
            image='https://media.vatreniput.hns.family/images/vatroslav_upute_2_xjcpuj.png'
            alt='Vatreni Kvizovi'
            sx={{
              width: { xs: '40%', md: '80%' },
              height: 'auto',
              margin: 'auto',
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          {votingInfoData.map((item, index) => (
            <Accordion sx={{ my: 2 }} key={index}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant='h6'>{item.title}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography sx={{ textAlign: 'left' }}>
                  {item.content}
                </Typography>
              </AccordionDetails>
            </Accordion>
          ))}
        </Grid>
      </Grid>
    </Container>
  );
};

export default VotingRewardInfo;
