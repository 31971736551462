import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from '../hooks';
import { paths } from 'src/routes/paths';
import { LoadingScreen } from 'src/components/loading-screen';

type Props = {
  children: React.ReactNode;
};

const AuthGuard = ({ children }: Props) => {
  const navigate = useNavigate();
  const { authenticated, method, initializing } = useAuthContext();
  const isInsideApp = document.referrer.includes(
    'vatreniput.hns.family'
  );
  const isCard = window.location.href.includes('/card/');

  useEffect(() => {
    if (!isInsideApp && !isCard && !authenticated) {
      sessionStorage.setItem(
        'preAuthRedirectUrl',
        window.location.href
      );
      navigate('/cardViewInstructions');
      return;
    }
    if (!initializing && !authenticated) {
      const loginPath =
        method === 'jwt' ? paths.auth.jwt.login : '/login';
      const searchParams = new URLSearchParams({
        returnTo: window.location.pathname,
      }).toString();
      navigate(`${loginPath}?${searchParams}`, { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authenticated, method]);

  return <>{initializing ? <LoadingScreen /> : children}</>;
};

export default AuthGuard;
