import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import { GuestGuard } from 'src/auth/guard';
import AuthClassicLayout from 'src/layouts/auth/classic';
import { SplashScreen } from 'src/components/loading-screen';
import { REGISTRATION_TOKEN } from '../paths';

// const JwtLoginPage = lazy(() => import('src/pages/auth/jwt/login'));
// const JwtRegisterPage = lazy(() => import('src/pages/auth/jwt/register'));
const DownloadAppScreen = lazy(
  () => import('src/sections/download-mobile-info/download-mobile-info')
);

const authJwt = {
  path: 'jwt',
  element: (
    <GuestGuard>
      <Suspense fallback={<SplashScreen />}>
        <Outlet />
      </Suspense>
    </GuestGuard>
  ),
  children: [
    {
      path: 'login',
      element: (
        <AuthClassicLayout
          title='Dobrodošli na digitalnu platformu Vatrenog puta!'
          image='/assets/images/vrsaljkoZastava.jpg'
        >
          {/* <JwtLoginPage /> */}
          <DownloadAppScreen />
        </AuthClassicLayout>
      ),
    },
    {
      path: `register/${REGISTRATION_TOKEN}`,
      element: (
        <AuthClassicLayout
          title='Dobrodošli na digitalnu platformu Vatrenog puta!'
          image='/assets/images/vrsaljkoZastava.jpg'
        >
          <DownloadAppScreen />
        </AuthClassicLayout>
      ),
    },
  ],
};

export const authRoutes = [
  {
    path: 'auth',
    children: [authJwt],
  },
];
