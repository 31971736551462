import { Navigate, useRoutes } from 'react-router-dom';
// config
import { PATH_AFTER_LOGIN } from 'src/config-global';
import { mainRoutes } from './main';
import { authRoutes } from './auth';
import { dashboardRoutes } from './dashboard';
import { lazy } from 'react';

const CardPage = lazy(() => import('src/pages/card/card'));
const CardInstructionsPage = lazy(
  () => import('src/pages/card-instructions-page')
);
const VideoHighlight = lazy(
  () => import('src/pages/video-highlight')
);

const Router = () => {
  return useRoutes([
    {
      path: '/',
      element: <Navigate to={PATH_AFTER_LOGIN} replace />,
    },
    {
      path: 'card/:cardId/:printedCountNumber',
      element: <CardPage />,
    },
    {
      path: 'cardViewInstructions',
      element: <CardInstructionsPage />,
    },
    {
      path: 'video/:videoName',
      element: <VideoHighlight />,
    },

    // Auth routes
    ...authRoutes,

    // Dashboard routes
    ...dashboardRoutes,

    // Main routes
    ...mainRoutes,

    // No match 404
    { path: '*', element: <Navigate to='/404' replace /> },
  ]);
};
export default Router;
