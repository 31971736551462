import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Tooltip from '@mui/material/Tooltip';
import ListItemText from '@mui/material/ListItemText';
import { RouterLink } from 'src/routes/components';
import Iconify from '../../iconify';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import { NavItemProps, NavConfigProps } from '../types';
import { StyledItem, StyledIcon, StyledDotIcon } from './styles';

type Props = NavItemProps & {
  config: NavConfigProps;
};

const NavItem = ({
  item,
  open,
  depth,
  active,
  config,
  externalLink,
  rewardStatus,
  ...other
}: Props) => {
  const { title, path, icon, info, children, disabled, caption, roles } = item;
  const markRewardQuiz =title === 'Kvizovi' && rewardStatus;
  const subItem = depth !== 1;
  const renderContent = (
    <StyledItem
      disableGutters
      disabled={disabled}
      active={active}
      depth={depth}
      config={config}
      {...other}
    >
      <>
        {icon && <StyledIcon size={config.iconSize}>{icon}</StyledIcon>}

        {subItem && (
          <StyledIcon size={config.iconSize}>
            <StyledDotIcon active={active} />
          </StyledIcon>
        )}
      </>
      {!(config.hiddenLabel && !subItem) && (
  <>
    <ListItemText
      primary={
        <div style={{ display: 'inline-block' }}>
    {title} {markRewardQuiz && <EmojiEventsIcon style={{ color: '#FFD700', marginLeft: 4, verticalAlign: 'middle' }} />}
  </div>
      }
      secondary={
        caption ? (
          <Tooltip title={caption} placement='top-start'>
            <span>{caption}</span>
          </Tooltip>
        ) : null
      }
      primaryTypographyProps={{
        noWrap: true,
        typography: 'body2',
        textTransform: 'capitalize',
        fontWeight: active ? 'fontWeightSemiBold' : 'fontWeightMedium',
      }}
      secondaryTypographyProps={{
        noWrap: true,
        component: 'span',
        typography: 'caption',
        color: 'text.disabled',
      }}
    />
  </>
)}
      {info && (
        <Box component='span' sx={{ ml: 1, lineHeight: 0 }}>
          {info}
        </Box>
      )}
      {!!children && (
        <Iconify
          width={16}
          icon={
            open ? 'eva:arrow-ios-downward-fill' : 'eva:arrow-ios-forward-fill'
          }
          sx={{ ml: 1, flexShrink: 0 }}
        />
      )}
    </StyledItem>
  );

  if (roles && !roles.includes(`${config.currentRole}`)) {
    return null;
  }

  if (externalLink)
    return (
      <Link
        href={path}
        target='_blank'
        rel='noopener'
        underline='none'
        color='inherit'
        sx={{
          ...(disabled && {
            cursor: 'default',
          }),
        }}
      >
        {renderContent}
      </Link>
    );

  if (children) {
    return renderContent;
  }

  return (
    <Link
      component={RouterLink}
      href={path}
      underline='none'
      color='inherit'
      sx={{
        ...(disabled && {
          cursor: 'default',
        }),
      }}
    >
      {renderContent}
    </Link>
  );
};
export default NavItem;
