import { memo, useState, useCallback, useEffect } from 'react';
// @mui
import List from '@mui/material/List';
import Stack from '@mui/material/Stack';
import Collapse from '@mui/material/Collapse';
//
import { NavSectionProps, NavListProps, NavConfigProps } from '../types';
import { navVerticalConfig } from '../config';
import { StyledSubheader } from './styles';
import useFetchQuizzes from 'src/hooks/use-quiz-data';
import dayjs from 'dayjs';

import NavList from './nav-list';

// ----------------------------------------------------------------------

function NavSectionVertical({ data, config, sx, ...other }: NavSectionProps) {
  const [rewardStatus, setRewardStatus] = useState<boolean>(false);
  const { unresolvedQuizzes, fetchUnresolvedQuizzes } = useFetchQuizzes();

  useEffect(() => {
    fetchUnresolvedQuizzes();
  }, []);

  useEffect(() => {
    if (unresolvedQuizzes) {
      const hasRewardableQuiz = unresolvedQuizzes.some((data) => {
        const createdAt = dayjs(data.createdAt);
        const diffInHours = dayjs().diff(createdAt, 'hour');
        return diffInHours < 62;
      });
      setRewardStatus(hasRewardableQuiz);
    }
  }, [unresolvedQuizzes]);

  return (
    <Stack sx={sx} {...other}>
      {data.map((group, index) => (
        <Group
          key={group.subheader || index}
          subheader={group.subheader}
          items={group.items}
          config={navVerticalConfig(config)}
          rewardStatus={rewardStatus}
        />
      ))}
    </Stack>
  );
}

export default memo(NavSectionVertical);

// ----------------------------------------------------------------------

type GroupProps = {
  subheader: string;
  items: NavListProps[];
  config: NavConfigProps;
  rewardStatus: boolean;
};

function Group({ subheader, items, config, rewardStatus }: GroupProps) {
  const [open, setOpen] = useState(true);

  const handleToggle = useCallback(() => {
    setOpen((prev) => !prev);
  }, []);

  const renderContent = items.map((list) => (
    <NavList
      key={list.title + list.path}
      data={list}
      depth={1}
      hasChild={!!list.children}
      config={config}
      rewardStatus={rewardStatus}
    />
  ));

  return (
    <List disablePadding sx={{ px: 2 }}>
      {subheader ? (
        <>
          <StyledSubheader disableGutters disableSticky onClick={handleToggle} config={config}>
            {subheader}
          </StyledSubheader>

          <Collapse in={open}>{renderContent}</Collapse>
        </>
      ) : (
        renderContent
      )}
    </List>
  );
}
